







import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {

    footerLayout: () => import('@/components/cmxBusiness/mobile/footer/InsFooterLayout.vue')
  }
})
export default class InsFooter extends Vue {
  // layout: string = 'footerLayout1';
}
